import {
  Anchor,
  BarChart,
  BarChart2,
  BookOpen,
  ChevronRight,
  Clipboard,
  Code,
  CreditCard,
  Database,
  DollarSign,
  FileMinus,
  FilePlus,
  Info,
  Layers,
  List,
  Mail,
  Maximize,
  Minus,
  PieChart,
  Plus,
  PlusSquare,
  Settings,
  ShoppingBag,
  Tag,
  Tool,
  Truck,
  User,
  Users,
} from 'react-feather';

// --- Dashboard ---
import DashboardIndex from '../pages/dashboard/Index';

// --- Accounting ---

// import AccountingDSPPP from '../pages/accounting/DSPPP/Index'

// --- Customer-Management ---
import CustomerCreate from '../pages/customer/Create';
import CustomerEdit from '../pages/customer/Edit';
import CustomerIndex from '../pages/customer/Index';
import CustomerShow from '../pages/customer/Show';

// --- Due-Management ---
import DueIndex from '../pages/dueManagement/Index';

// --- E-commerce ---

// --- Employee-Management ---
import EmployeeAttendanceReport from '../pages/employeeManagement/EmployeeAttendanceReport';
import EmployeeAttendanceReportView from '../pages/employeeManagement/EmployeeAttendanceReportView';
import EmployeeBonus from '../pages/employeeManagement/EmployeeBonus';
import EmployeeCreate from '../pages/employeeManagement/EmployeeCreate';
import EmployeeEdit from '../pages/employeeManagement/EmployeeEdit';
import EmployeeList from '../pages/employeeManagement/EmployeeList';
import EmployeeProfileShow from '../pages/employeeManagement/EmployeeProfileShow';
import EmployeeSalary from '../pages/employeeManagement/EmployeeSalary';

//----account management---
import AccountList from '../pages/accountManagement/AccountList';
import AddAccount from '../pages/accountManagement/AddAccount';
import AddExpense from '../pages/accountManagement/AddExpense';
import EditAccount from '../pages/accountManagement/EditAccount';
import EditExpense from '../pages/accountManagement/EditExpense';
import EditWithdraw from '../pages/accountManagement/EditWithdraw';
import AddWithdraw from '../pages/accountManagement/Withdraw';
// --- Inventory-Management ---

// --- Inventory-Management / Product ---
import InventoryBrandIndex from '../pages/inventory/product/Brand';
import InventoryCategoryIndex from '../pages/inventory/product/Category';
import InventoryProductCreate from '../pages/inventory/product/Create';
import InventoryProductIndex from '../pages/inventory/product/Index';
import InventoryProductShow from '../pages/inventory/product/Show';
import InventoryProductUpdate from '../pages/inventory/product/Update';

// --- Inventory-Management / Stock ---
import InventoryStockIn from '../pages/inventory/stock/StockIn';
import InventoryStockInHistory from '../pages/inventory/stock/StockInHistory';
import InventoryStockOut from '../pages/inventory/stock/StockOut';
import InventoryStockOutHistory from '../pages/inventory/stock/StockOutHistory';
import InventoryStockStatus from '../pages/inventory/stock/StockStatus';
// import InventoryStockAdd from '../pages/inventory/stock/StockAdd'

// --- Inventory-Management / Supplier ---
import InventorySupplierAdd from '../pages/inventory/supplier/Create';
import InventorySupplierEdit from '../pages/inventory/supplier/Edit';
import InventorySupplierIndex from '../pages/inventory/supplier/Index';
import InventorySupplierShow from '../pages/inventory/supplier/Show';
import InventorySupplierBill from '../pages/inventory/supplier/BillIndex';
import InventorySupplierBillCreate from '../pages/inventory/supplier/BillCreate';
import InventorySupplierBillEdit from '../pages/inventory/supplier/BillEdit';

// --- IMEI Code-Management ---
import IMEISeperateProductCode from '../pages/imei/SeperateProductCode';
import IMEISingleProductCode from '../pages/imei/SingleProductCode';

// --- Installment-Management ---

// --- Mechanic & Servicing ---
// --- Mechanic
import MechanicServicingMechanicEdit from '../pages/mechanicAndServicing/mechanic/Edit';
import MechanicServicingMechanicIndex from '../pages/mechanicAndServicing/mechanic/Index';
import MechanicServicingMechanicShow from '../pages/mechanicAndServicing/mechanic/Show';
import MechanicServicingMechanicStore from '../pages/mechanicAndServicing/mechanic/Store';

// --- Servicing
import MechanicServicingServicingEdit from '../pages/mechanicAndServicing/servicing/Edit';
import MechanicServicingServicingIndex from '../pages/mechanicAndServicing/servicing/Index';
import MechanicServicingServicingPrint from '../pages/mechanicAndServicing/servicing/Print';
import MechanicServicingServicingShow from '../pages/mechanicAndServicing/servicing/Show';
import MechanicServicingServicingStore from '../pages/mechanicAndServicing/servicing/Store';

// --- POS ---
import PosOrderIndex from '../pages/pos/order/Index';
import PosOrderReturn from '../pages/pos/order/return';
import PosOrderShow from '../pages/pos/order/Show';
import PosPointOfSellIndex from '../pages/pos/pos/Index';
// import ServicingIndex from '../pos/servicing/Index'

// --- Product return & replacement ---
import ProductReturnAndReplacement from '../pages/productReturnAndReplacement/Index';
import ProductReturnAndReplacementShow from '../pages/productReturnAndReplacement/Show';

import ProductReturn from '../pages/productReturn/Index';
// --- SMS ---
import SmsIndex from '../pages/sms/Index';
import SmsPackages from '../pages/sms/Packages';
import SmsSend from '../pages/sms/Send';

// --- Reports ---
import ReportCustomer from '../pages/reports/Customer';
import ReportPurchase from '../pages/reports/PurchaseReport';
import ReportDue from '../pages/reports/DueReport';
import ReportRevenue from '../pages/reports/Revenue';
import ReportSales from '../pages/reports/Sales';
import ReportServiceRevenue from '../pages/reports/Servicing';
import ReportStock from '../pages/reports/Stock';

// --- Notice ---

// --- Settings ---
import SettingsIndex from '../pages/settings/Index';

// --- Static ---
import StaticAbout from '../pages/about/Index';
import StaticCommunityStandard from '../pages/communityStandard/Index';
import StaticCookies from '../pages/cookies/Index';
import StaticDataPolicy from '../pages/dataPolicy/Index';
import StaticTermOfServices from '../pages/termOfService/Index';

export const routes = [
  // Dashboard
  {
    title: 'Dashboard',
    name: 'dashboard',
    path: '/dashboard',
    exact: true,
    inDrawer: true,
    icon: <PieChart size={16} />,
    component: DashboardIndex,
  },

  // Accounting Old
  // {
  //   title: 'Accounting',
  //   name: 'accounting',
  //   inDrawer: true,
  //   icon: <Book size={16} />,
  //   children: [
  //     {
  //       title: 'Ledger',
  //       name: 'accounting ledger index',
  //       exact: true,
  //       inDrawer: true,
  //       icon: <Database size={16} />,
  //       path: '/dashboard/accounting/ledger',
  //       component: AccountingLedgerIndex,
  //     },
  //     {
  //       title: 'Ledger show',
  //       name: 'accounting ledger show',
  //       exact: true,
  //       inDrawer: false,
  //       icon: null,
  //       path: '/dashboard/accounting/ledger/:id',
  //       component: AccountingLedgerShow,
  //     },
  //     {
  //       title: 'Cash',
  //       name: 'accounting cash index',
  //       exact: true,
  //       inDrawer: true,
  //       icon: <DollarSign size={16} />,
  //       path: '/dashboard/accounting/cash',
  //       component: AccountingCashIndex,
  //     },
  //     {
  //       title: 'Daily Sell',
  //       name: 'accounting daily sell',
  //       exact: true,
  //       inDrawer: true,
  //       icon: <BarChart size={16} />,
  //       path: '/dashboard/accounting/daily-sell',
  //       component: AccountingDailySell,
  //     },
  //     {
  //       title: 'Daily Due',
  //       name: 'accounting daily due',
  //       exact: true,
  //       inDrawer: true,
  //       icon: <DollarSign size={16} />,
  //       path: '/dashboard/accounting/daily-due',
  //       component: AccountingDailyDue,
  //     },
  //     {
  //       title: 'Daily expense',
  //       name: 'accounting daily expense',
  //       exact: true,
  //       inDrawer: true,
  //       icon: <BarChart2 size={16} />,
  //       path: '/dashboard/accounting/daily-expense',
  //       component: AccountingDailyExpense,
  //     },
  //     {
  //       title: 'Daily expense create',
  //       name: 'accounting daily expense create',
  //       exact: true,
  //       inDrawer: false,
  //       icon: null,
  //       path: '/dashboard/accounting/daily-expense/create',
  //       component: AccountingDailyExpenseCreate,
  //     },
  //     {
  //       title: 'Daily payable',
  //       name: 'accounting daily payable',
  //       exact: true,
  //       inDrawer: true,
  //       icon: <Truck size={16} />,
  //       path: '/dashboard/accounting/daily-payable',
  //       component: AccountingDailyPayable,
  //     },
  //     {
  //       title: 'Daily payable create',
  //       name: 'accounting daily payable create',
  //       exact: true,
  //       inDrawer: false,
  //       icon: null,
  //       path: '/dashboard/accounting/daily-payable/create',
  //       component: AccountingDailyPayableCreate,
  //     },
  //   ],
  // },

  // Customer management
  {
    title: 'Customer management',
    name: 'customer index',
    exact: true,
    inDrawer: true,
    icon: <Users size={16} />,
    path: '/dashboard/customer',
    component: CustomerIndex,
  },
  {
    title: 'Customer create',
    name: 'customer create',
    exact: true,
    inDrawer: false,
    icon: null,
    path: '/dashboard/customer/create',
    component: CustomerCreate,
  },
  {
    title: 'Customer edit',
    name: 'customer edit',
    exact: true,
    inDrawer: false,
    icon: null,
    path: '/dashboard/customer/edit/:id',
    component: CustomerEdit,
  },
  {
    title: 'Customer show',
    name: 'customer show',
    exact: true,
    inDrawer: false,
    icon: null,
    path: '/dashboard/customer/show/:id',
    component: CustomerShow,
  },

  // Due management
  {
    title: 'Due management',
    name: 'due',
    exact: true,
    inDrawer: true,
    icon: <BookOpen size={16} />,
    path: '/dashboard/due-management',
    component: DueIndex,
  },

  // POS
  {
    title: 'Point of Sale',
    name: 'pos',
    inDrawer: true,
    exact: true,
    icon: <Database size={16} />,
    path: '/dashboard/pos/point-of-sell',
    component: PosPointOfSellIndex,
  },

  // Order
  {
    title: 'Orders',
    name: 'orders',
    inDrawer: true,
    exact: true,
    icon: <Truck size={16} />,
    path: '/dashboard/orders',
    component: PosOrderIndex,
  },
  {
    title: 'Order Show',
    name: 'orders show',
    inDrawer: false,
    exact: true,
    icon: <Tool size={16} />,
    path: '/dashboard/order/show/:id',
    component: PosOrderShow,
  },
  {
    title: 'Order Show',
    name: 'orders show',
    inDrawer: false,
    exact: true,
    icon: <Tool size={16} />,
    path: '/dashboard/order/return/:id',
    component: PosOrderReturn,
  },

  // Inventory management

  {
    title: 'Inventory management',
    isStatic: true,
    name: 'inventory',
    inDrawer: true,
    icon: <Clipboard size={16} />,
  },
  {
    title: 'Product',
    name: 'inventory product',
    inDrawer: true,
    icon: <PieChart size={16} />,
    children: [
      {
        title: 'Add New Product',
        name: 'inventory product add',
        inDrawer: true,
        exact: true,
        icon: <Tag size={16} />,
        path: '/dashboard/inventory/product/new',
        component: InventoryProductCreate,
      },

      {
        title: 'Product Edit',
        name: 'inventory product list',
        inDrawer: false,
        exact: true,
        icon: null,
        path: '/dashboard/inventory/product/edit/:id',
        component: InventoryProductUpdate,
      },
      {
        title: 'Product Show',
        name: 'inventory product Show',
        inDrawer: false,
        exact: true,
        icon: null,
        path: '/dashboard/inventory/product/show/:id',
        component: InventoryProductShow,
      },
      {
        title: 'Product Category',
        name: 'inventory product category',
        inDrawer: true,
        exact: true,
        icon: <BookOpen size={16} />,
        path: '/dashboard/inventory/product/category',
        component: InventoryCategoryIndex,
      },
      {
        title: 'Product Brands',
        name: 'inventory product brands',
        inDrawer: true,
        exact: true,
        icon: <Layers size={16} />,
        path: '/dashboard/inventory/product/brands',
        component: InventoryBrandIndex,
      },
      {
        title: 'Product List',
        name: 'inventory product list',
        inDrawer: true,
        exact: true,
        icon: <List size={16} />,
        path: '/dashboard/inventory/product/list',
        component: InventoryProductIndex,
      },
    ],
  },
  {
    title: 'Stock',
    name: 'inventory stock',
    inDrawer: true,
    icon: <Anchor size={16} />,
    children: [
      {
        title: 'Stock Status',
        name: 'inventory stock status',
        inDrawer: true,
        exact: true,
        icon: <Database size={16} />,
        path: '/dashboard/inventory/stock/status',
        component: InventoryStockStatus,
      },
      {
        title: 'Stock In History',
        name: 'inventory stock in-history',
        inDrawer: true,
        exact: true,
        icon: <FilePlus size={16} />,
        path: '/dashboard/inventory/stock/in-history',
        component: InventoryStockInHistory,
      },
      {
        title: 'Stock Out History',
        name: 'inventory stock out-history',
        inDrawer: true,
        exact: true,
        icon: <FileMinus size={16} />,
        path: '/dashboard/inventory/stock/out-history',
        component: InventoryStockOutHistory,
      },
      {
        title: 'Stock In',
        name: 'inventory stock in',
        inDrawer: true,
        exact: true,
        icon: <Plus size={16} />,
        path: '/dashboard/inventory/stock/in',
        component: InventoryStockIn,
      },
      {
        title: 'Stock Out',
        name: 'inventory stock out',
        inDrawer: true,
        exact: true,
        icon: <Minus size={16} />,
        path: '/dashboard/inventory/stock/out',
        component: InventoryStockOut,
      },
    ],
  },
  {
    title: 'Suppliers',
    name: 'supplier',
    inDrawer: true,
    icon: <Users size={16} />,
    children: [
      {
        title: 'Supplier Add',
        name: 'supplier add',
        inDrawer: true,
        exact: true,
        icon: <Plus size={16} />,
        path: '/dashboard/inventory/supplier/add',
        component: InventorySupplierAdd,
      },
      {
        title: 'Supplier List',
        name: 'supplier list',
        inDrawer: true,
        exact: true,
        icon: <List size={16} />,
        path: '/dashboard/inventory/supplier/list',
        component: InventorySupplierIndex,
      },

      {
        title: 'Supplier List',
        name: 'supplier list',
        inDrawer: false,
        exact: true,
        icon: null,
        path: '/dashboard/inventory/supplier/edit/:id',
        component: InventorySupplierEdit,
      },
      {
        title: 'Supplier Bill',
        name: 'supplier bill index',
        inDrawer: true,
        exact: true,
        icon: <Info size={16} />,
        path: '/dashboard/inventory/supplier-bill/list',
        component: InventorySupplierBill,
      },
      {
        title: 'Supplier Bill',
        name: 'supplier bill create',
        inDrawer: false,
        exact: true,
        icon: <Info size={16} />,
        path: '/dashboard/inventory/supplier-bill/add/:id',
        component: InventorySupplierBillCreate,
      },
      {
        title: 'Supplier Bill',
        name: 'supplier bill create',
        inDrawer: false,
        exact: true,
        icon: <Info size={16} />,
        path: '/dashboard/inventory/supplier-bill/add',
        component: InventorySupplierBillCreate,
      },
      {
        title: 'Supplier Bill',
        name: 'supplier bill edit',
        inDrawer: false,
        exact: true,
        icon: <Info size={16} />,
        path: '/dashboard/inventory/supplier-bill/edit/:id',
        component: InventorySupplierBillEdit,
      },
      {
        title: 'Supplier Show',
        name: 'supplier show',
        inDrawer: false,
        exact: true,
        icon: null,
        path: '/dashboard/inventory/supplier/show/:id',
        component: InventorySupplierShow,
      },
    ],
  },
  // IMEI/Separate Code Management
  {
    title: 'IMEI/Separate Code Management',
    name: 'imei',
    inDrawer: true,
    icon: <Code size={16} />,
    children: [
      {
        title: 'Single Product Code',
        name: 'imei single-product-code',
        exact: true,
        inDrawer: true,
        icon: <ChevronRight size={16} />,
        path: '/dashboard/imei/single',
        component: IMEISingleProductCode,
      },
      {
        title: 'Separate Product Code',
        name: 'imei separate-product-code',
        exact: true,
        inDrawer: true,
        icon: <List size={16} />,
        path: '/dashboard/imei/multiple',
        component: IMEISeperateProductCode,
      },
    ],
  },
  {
    title: 'Product Return & Replacement',
    name: 'product-return-replacement',
    // exact: true,
    inDrawer: true,
    icon: <ShoppingBag size={16} />,
    // path: '/dashboard/product-return-replacement',
    // component: ProductReturnAndReplacement,

    children: [
      {
        title: 'Product Return History',
        name: 'imei single-product-code',
        exact: true,
        inDrawer: true,
        icon: <ChevronRight size={16} />,
        path: '/dashboard/product-return-replacement',
        component: ProductReturnAndReplacement,
      },
      {
        title: 'Product Return',
        name: 'imei separate-product-code',
        exact: true,
        inDrawer: true,
        icon: <List size={16} />,
        path: '/dashboard/product-return',
        component: ProductReturn,
      },
    ],
  },
  // account manag
  {
    title: 'Account Management',
    isStatic: true,
    name: 'inventory',
    inDrawer: true,
    icon: <Clipboard size={16} />,
  },
  {
    title: 'Accounting',
    name: 'employee',
    inDrawer: true,
    icon: <CreditCard size={16} />,
    children: [
      {
        title: 'Add Money',
        name: 'employee list',
        exact: true,
        inDrawer: true,
        icon: <PlusSquare size={16} />,
        path: '/dashboard/accounting/add-money',
        component: AddAccount,
      },
      {
        title: 'Edit Money',
        name: 'Edit money',
        exact: true,
        inDrawer: false,
        icon: null,
        path: '/dashboard/accounting/edit-money/:id',
        component: EditAccount,
      },
      {
        title: 'Edit withdraw',
        name: 'Edit money',
        exact: true,
        inDrawer: false,
        icon: null,
        path: '/dashboard/accounting/edit-withdraw/:id',
        component: EditWithdraw,
      },
      {
        title: 'Edit expense',
        name: 'Edit money',
        exact: true,
        inDrawer: false,
        icon: null,
        path: '/dashboard/accounting/edit-expense/:id',
        component: EditExpense,
      },
      {
        title: 'Money Withdraw',
        name: 'employee create',
        exact: true,
        inDrawer: true,
        icon: <Maximize size={16} />,
        path: '/dashboard/accounting/withdraw-money',
        component: AddWithdraw,
      },
      {
        title: 'Add Expense',
        name: 'employee edit',
        exact: true,
        inDrawer: true,
        icon: <PlusSquare size={16} />,
        path: '/dashboard/accounting/add-expense',
        component: AddExpense,
      },
      {
        title: 'Account List',
        name: 'employee show',
        exact: true,
        inDrawer: true,
        icon: <List size={16} />,
        path: '/dashboard/accounting/account-list',
        component: AccountList,
      },
    ],
  },
  // Reports
  {
    title: 'Reports',
    name: 'reports',
    inDrawer: true,
    icon: <BarChart2 size={16} />,
    children: [
      {
        title: 'Customer Report',
        name: 'reports customer-report',
        inDrawer: true,
        exact: true,
        icon: <Users size={16} />,
        path: '/dashboard/reports/customers',
        component: ReportCustomer,
      },
      {
        title: 'Purchase Report',
        name: 'reports purchase-report',
        inDrawer: true,
        exact: true,
        icon: <DollarSign size={16} />,
        path: '/dashboard/reports/purchase-report',
        component: ReportPurchase,
      },
      {
        title: 'Due Report',
        name: 'reports due-report',
        inDrawer: true,
        exact: true,
        icon: <DollarSign size={16} />,
        path: '/dashboard/reports/due-report',
        component: ReportDue,
      },
      {
        title: 'Revenue Report',
        name: 'reports revenue-report',
        inDrawer: true,
        exact: true,
        icon: <BarChart size={16} />,
        path: '/dashboard/reports/revenue',
        component: ReportRevenue,
      },
      {
        title: 'Sales Report',
        name: 'reports sales-report',
        inDrawer: true,
        exact: true,
        icon: <DollarSign size={16} />,
        path: '/dashboard/reports/sales',
        component: ReportSales,
      },

      {
        title: 'Stock Report',
        name: 'reports stock-report',
        inDrawer: true,
        exact: true,
        icon: <BarChart2 size={16} />,
        path: '/dashboard/reports/stock',
        component: ReportStock,
      },
    ],
  },

  // E-commerce
  // {
  //     title: "E-commerce",
  //     name: "ecommerce",
  //     exact: true,
  //     inDrawer: true,
  //     icon: <ShoppingCart size={16} />,
  //     path: "/dashboard/ecommerce/orders",
  //     component: EcomOrderIndex
  // },
  // {
  //     title: "E-commerce order show",
  //     name: "ecommerce order-show",
  //     exact: true,
  //     inDrawer: false,
  //     icon: null,
  //     path: "/dashboard/ecommerce/orders/:id",
  //     component: EcomOrderShow
  // },

  // account management

  // Employee management
  {
    title: 'Employee management',
    name: 'employee',
    inDrawer: false,
    icon: <User size={16} />,
    children: [
      {
        title: 'Employee List',
        name: 'employee list',
        exact: true,
        inDrawer: true,
        icon: <List size={16} />,
        path: '/dashboard/employee-management/list',
        component: EmployeeList,
      },
      {
        title: 'Employee Create',
        name: 'employee create',
        exact: true,
        inDrawer: false,
        icon: null,
        path: '/dashboard/employee-management/create',
        component: EmployeeCreate,
      },
      {
        title: 'Employee Edit',
        name: 'employee edit',
        exact: true,
        inDrawer: false,
        icon: null,
        path: '/dashboard/employee-management/edit/:id',
        component: EmployeeEdit,
      },
      {
        title: 'Employee Show',
        name: 'employee show',
        exact: true,
        inDrawer: false,
        icon: null,
        path: '/dashboard/employee-management/profile-show/:id',
        component: EmployeeProfileShow,
      },
      // {
      //     title: "Employee attendance",
      //     name: "employee attendance",
      //     exact: true,
      //     inDrawer: true,
      //     icon: <Anchor size={16} />,
      //     path: "/dashboard/employee-management/attendance",
      //     component: EmployeeAttendance
      // },
      // {
      //     title: "Employee overtime",
      //     name: "employee overtime",
      //     exact: true,
      //     inDrawer: true,
      //     icon: <Clock size={16} />,
      //     path: "/dashboard/employee-management/overtime",
      //     component: EmployeeOvertime
      // },
      {
        title: 'Employee Bonus',
        name: 'employee bonus',
        exact: true,
        inDrawer: true,
        icon: <DollarSign size={16} />,
        path: '/dashboard/employee-management/bonus',
        component: EmployeeBonus,
      },
      {
        title: 'Employee salary',
        name: 'employee salary',
        exact: true,
        inDrawer: true,
        icon: <DollarSign size={16} />,
        path: '/dashboard/employee-management/salary',
        component: EmployeeSalary,
      },
      {
        title: 'Employee attendance-report',
        name: 'employee attendance report',
        exact: true,
        inDrawer: true,
        icon: <BarChart2 size={16} />,
        path: '/dashboard/employee-management/attendance-report',
        component: EmployeeAttendanceReport,
      },
      {
        title: 'Employee attendance-report view',
        name: 'employee attendance report view',
        exact: true,
        inDrawer: false,
        path: '/dashboard/employee-management/attendance-report-view/:id',
        component: EmployeeAttendanceReportView,
      },
    ],
  },

  // // Installment Management
  // {
  //     title: "Installment Management",
  //     name: "installment-management",
  //     inDrawer: true,
  //     exact: true,
  //     icon: <BatteryCharging size={16} />,
  //     path: "/dashboard/installment-management",
  //     component: InstallmentIndex
  // },

  // // Messaging-Messenger
  // {
  //     title: "Messaging/Messenger",
  //     name: "messaging-messenger",
  //     inDrawer: true,
  //     exact: true,
  //     icon: <MessageCircle size={16} />,
  //     path: "/messenger",
  // },

  // Mechanic & Servicing
  {
    title: 'Mechanic & Servicing',
    name: 'mechanic-servicing',
    inDrawer: false,
    icon: <Tool size={16} />,
    children: [
      {
        title: 'Mechanic',
        name: 'mechanic-servicing mechanic',
        inDrawer: true,
        exact: true,
        icon: <Users size={16} />,
        path: '/dashboard/mechanic',
        component: MechanicServicingMechanicIndex,
      },
      {
        title: 'Mechanic Store',
        name: 'mechanic-servicing mechanic-store',
        inDrawer: false,
        exact: true,
        icon: null,
        path: '/dashboard/mechanic/create',
        component: MechanicServicingMechanicStore,
      },
      {
        title: 'Mechanic Edit',
        name: 'mechanic-servicing mechanic-edit',
        inDrawer: false,
        exact: true,
        icon: null,
        path: '/dashboard/mechanic/edit/:id',
        component: MechanicServicingMechanicEdit,
      },
      {
        title: 'Mechanic Show',
        name: 'mechanic-servicing mechanic-show',
        inDrawer: false,
        exact: true,
        icon: null,
        path: '/dashboard/mechanic/show/:id',
        component: MechanicServicingMechanicShow,
      },
      {
        title: 'Servicing',
        name: 'mechanic-servicing servicing',
        inDrawer: true,
        exact: true,
        icon: <Settings size={16} />,
        path: '/dashboard/servicing',
        component: MechanicServicingServicingIndex,
      },
      {
        title: 'Servicing Store',
        name: 'mechanic-servicing servicing-store',
        inDrawer: false,
        exact: true,
        icon: null,
        path: '/dashboard/servicing/create',
        component: MechanicServicingServicingStore,
      },
      {
        title: 'Servicing Edit',
        name: 'mechanic-servicing servicing-edit',
        inDrawer: false,
        exact: true,
        icon: null,
        path: '/dashboard/servicing/edit/:id',
        component: MechanicServicingServicingEdit,
      },
      {
        title: 'Servicing Show',
        name: 'mechanic-servicing servicing-show',
        inDrawer: false,
        exact: true,
        icon: null,
        path: '/dashboard/servicing/show/:id',
        component: MechanicServicingServicingShow,
      },
      {
        title: 'Servicing Print',
        name: 'mechanic-servicing servicing-print',
        inDrawer: false,
        exact: true,
        icon: null,
        path: '/dashboard/servicing/print/:id',
        component: MechanicServicingServicingPrint,
      },
    ],
  },

  // Product Return & Replacement

  {
    title: 'Product Return & Replacement Show',
    name: 'product-return-replacement show',
    exact: true,
    inDrawer: false,
    icon: null,
    path: '/dashboard/product-return-replacement/show/:id',
    component: ProductReturnAndReplacementShow,
  },

  // SMS
  {
    title: 'SMS',
    name: 'sms',
    inDrawer: true,
    exact: true,
    icon: <Mail size={16} />,
    path: '/dashboard/sms',
    component: SmsIndex,
  },
  {
    title: 'SMS Packages',
    name: 'sms packages',
    inDrawer: false,
    exact: true,
    icon: false,
    path: '/dashboard/sms/packages',
    component: SmsPackages,
  },
  {
    title: 'SMS Send',
    name: 'sms send',
    inDrawer: false,
    exact: true,
    icon: false,
    path: '/dashboard/sms/send',
    component: SmsSend,
  },

  // Notice management
  // {
  //     title: "Notice",
  //     name: "notice",
  //     // exact: true,
  //     inDrawer: true,
  //     icon: <Bell size={16} />,
  //     children: [
  //         {
  //             title: "History",
  //             name: 'notice history',
  //             exact: true,
  //             inDrawer: true,
  //             icon: <List size={16}/>,
  //             path: "/dashboard/notice/index",
  //             component: NoticeIndex
  //         },
  //         {
  //             title: "Send",
  //             name: 'notice send',
  //             exact: true,
  //             inDrawer: true,
  //             icon: <Send size={16} />,
  //             path: "/dashboard/notice/send",
  //             component: NoticeSend
  //         }
  //     ]

  // },

  // Settings

  {
    title: 'Settings',
    name: 'settings',
    inDrawer: true,
    exact: true,
    icon: <Settings size={16} />,
    path: '/dashboard/settings',
    component: SettingsIndex,
  },

  // Static pages
  {
    title: 'Term of Services',
    name: 'term-of-services',
    inDrawer: false,
    exact: true,
    icon: null,
    path: '/dashboard/term-of-service',
    component: StaticTermOfServices,
  },
  {
    title: 'Data Policy',
    name: 'data-policy',
    inDrawer: false,
    exact: true,
    icon: null,
    path: '/dashboard/data-policy',
    component: StaticDataPolicy,
  },
  {
    title: 'Cookie Policy',
    name: 'cookie-policy',
    inDrawer: false,
    exact: true,
    icon: null,
    path: '/dashboard/cookies',
    component: StaticCookies,
  },
  {
    title: 'Community & Standard',
    name: 'community-standard',
    inDrawer: false,
    exact: true,
    icon: null,
    path: '/dashboard/community-standard',
    component: StaticCommunityStandard,
  },
  {
    title: 'About',
    name: 'about',
    inDrawer: false,
    exact: true,
    icon: null,
    path: '/dashboard/about',
    component: StaticAbout,
  },
];

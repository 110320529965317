import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Gift, Printer } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { PrimaryModal } from '../modal/PrimaryModal';
import { dateFormate } from '../../utils/_heplers';
import { Requests } from '../../utils/Http/Index';
import { DangerButton, GrayButton, SuccessButton } from '../button/Index';
import { DataTable } from '../table/Index';
import { Main } from '../layout/Index';
import { Container } from '../container/Index';
import { useParams } from 'react-router';
import JsBarcode from 'jsbarcode';

export const PurchaseHistory = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const templateRef = useRef();
  const barcodeRef = useRef(null);
  const [invoiceNo, setInvoiceNo] = useState('');

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [serverError, setServerError] = useState(false);
  const [searchLoading, setsearchLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [show_product_modal, setShowProductModal] = useState(false);
  const [productData, setProductData] = useState([]);
  const [invoiceData, setInvoiceData] = useState({});
  const [dokanData, setDokanData] = useState([]);
  const [logoData, setLogoData] = useState();
  const [isPrinting, setIsPrinting] = useState(false);
  // const generateInvoiceNo = async () => {
  //   try {
  //     const response = await Requests.POS.GenerateInvoice();
  //     setInvoiceNo(response?.data?.data?.invoice_number);

  //     setLoading(false);
  //   } catch (error) {
  //     setLoading(false);
  //   }
  // };

  // Fetch purchase history data
  const fetchPurchaseHistory = useCallback(
    async (page) => {
      setLoading(true);
      try {
        const response = await Requests.Customer.CustomerPurchaseHistory(
          id,
          page,
          perPage
        );
        setData(response.data.data);
        setTotalRows(response.data.meta.total);
        setLoading(false);
        setServerError(false);
      } catch (error) {
        if (error) {
          setLoading(false);
          setServerError(true);
        }
      }
    },
    [perPage]
  );

  const fetchInvoiceData = async (orderUID) => {
    try {
      const response = await Requests.Settings.InvoiceInfo(orderUID);
      if (response.status === 200) setInvoiceData(response.data.data);
      // await generateInvoiceNo(); // Generate the invoice number

      // handleGeneratePDF();
      setLoading(false);
    } catch (error) {
      if (error) {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (invoiceNo && barcodeRef.current) {
      JsBarcode(barcodeRef.current, invoiceNo, {
        format: 'CODE128',
        lineColor: '#000',
        width: 2,
        height: 40,
        displayValue: false,
      });
    }
  }, [invoiceNo]);

  const handlePageChange = (page) => fetchPurchaseHistory(page);

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    try {
      const response = await Requests.Customer.CustomerPurchaseHistory(
        id,
        page,
        perPage
      );
      if (response.status === 200) {
        setData(response.data.data);
        setPerPage(newPerPage);
        setLoading(false);
      }
    } catch (error) {
      if (error) {
        setLoading(false);
        setServerError(true);
      }
    }
  };

  useEffect(() => {
    fetchPurchaseHistory(1);
  }, [fetchPurchaseHistory]);

  const handleSuggestion = async (value) => {
    let data = {
      results: [],
      message: null,
    };
    const response = await Requests.Customer.CustomerSearch(value);
    if (response && response.data.data && response.data.data.length) {
      for (let i = 0; i < response.data.data.length; i++) {
        const element = response.data.data[i];
        data.results.push(element.name);
      }
    } else {
      data.message = 'No results found';
    }

    return data;
  };

  const handleGeneratePDF = async () => {
    // await generateInvoiceNo(); // Generate the invoice number

    setTimeout(() => {
      window.print(); // Opens the browser's print dialog after the delay
    }, 500); // Opens the browser's print dialog
  };
  // const handleGeneratePDF = async () => {
  //   if (isPrinting) return; // Prevent multiple print calls
  //   setIsPrinting(true);

  //   await generateInvoiceNo(); // Generate the invoice number

  //   setTimeout(() => {
  //     // Use a CSS class to hide all other elements except the template during printing
  //     document.body.classList.add('print-mode');

  //     window.print(); // Opens the browser's print dialog

  //     // After printing is done, remove the print-mode class to restore visibility
  //     window.onafterprint = () => {
  //       document.body.classList.remove('print-mode');
  //       setIsPrinting(false); // Allow future prints after completion
  //     };
  //   }, 100);
  // };

  const handleShowPopUp = (value) => {
    // setCreate({ show: true, loading: false });
    handleGeneratePDF();
    fetchInvoiceData(value.uid);
    setInvoiceNo(value.uid);
    console.log(value.uid, 'hhhh');
  };

  // Handle search
  const handleSearch = async (data) => {
    setsearchLoading(true);
    const response = await Requests.Customer.CustomerSearch(data);
    if (response.data) setData(response.data.data);
    setsearchLoading(false);
  };

  // Purchase History columns
  const columns = [
    {
      name: `${t('Invoice')}`,
      selector: (row) => row.invoice_id || 'N/A',
      sortable: true,
    },
    {
      name: `${t('Date')}`,
      selector: (row) => dateFormate(row.purchase_date) || 'N/A',
      sortable: true,
    },
    {
      name: `${t('Paid')}`,
      selector: (row) => row.amount_paid || 0,
      sortable: true,
    },
    {
      name: `${t('Due')}`,
      selector: (row) => row.amount_due || 0,
      sortable: true,
    },
    {
      name: `${t('Method')}`,
      selector: (row) => row.payment_method || 'N/A',
    },
    {
      name: 'Products',
      cell: (row) => (
        <div>
          <SuccessButton
            type='button'
            style={{ borderRadius: '50%', padding: '6px 9px', marginRight: 5 }}
            onClick={() => {
              setShowProductModal(true);
              setProductData(row.items);
            }}
          >
            <Gift size={16} />
          </SuccessButton>
          <GrayButton
            type='button'
            style={{ borderRadius: '50%', padding: '6px 9px', marginRight: 5 }}
            onClick={() => handleShowPopUp(row)}
          >
            <Printer size={16} />
          </GrayButton>
        </div>
      ),
    },
  ];

  // fetch data
  const fetchDokanData = useCallback(async () => {
    try {
      const response = await Requests.Settings.DokanSettingInformation();
      if (response.status === 200) setDokanData(response.data);
      setLogoData(response.data?.dokan?.logo);

      setLoading(false);
    } catch (error) {
      if (error) setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchDokanData();
  }, [fetchDokanData]);

  // Products data columns
  const productColumns = [
    {
      name: `${t('Name')}`,
      selector: (row) => row.product_name || 'N/A',
    },
    {
      name: `${t('Quantity')}`,
      selector: (row) => row.quantity || 0,
      sortable: true,
    },
    {
      name: `${t('Unit Price')}`,
      selector: (row) => row.unit_price || 0,
      sortable: true,
    },
    {
      name: `${t('Unit Discount')}`,
      selector: (row) => row.unit_discount || 0,
      sortable: true,
    },
    {
      name: `${t('Total Price')}`,
      selector: (row) => row.total_price || 0,
      sortable: true,
    },
    {
      name: `${t('Total Discount')}`,
      selector: (row) => row.total_discount || 0,
      sortable: true,
    },
    {
      name: `${t('Warrenty Type')}`,
      selector: (row) => row.warrenty_type || 'N/A',
    },
    {
      name: `${t('Warrenty Validity End At')}`,
      selector: (row) => dateFormate(row.date) || 'N/A',
      minWidth: '200px',
    },
  ];

  return (
    <div>
      <Main className='hide-during-print'>
        {/* Purchase history table */}

        <Container.Column>
          <DataTable
            columns={columns}
            data={data}
            loading={loading}
            totalRows={totalRows}
            handlePerRowsChange={handlePerRowsChange}
            handlePageChange={handlePageChange}
            noDataMessage='No purchase history available'
            searchable
            placeholder={'Search'}
            search={handleSearch}
            searchLoading={searchLoading}
            suggestion={handleSuggestion}
            clearSearch={() => fetchPurchaseHistory(1)}
          />
        </Container.Column>
      </Main>

      {invoiceData && dokanData && (
        //   ref={templateRef}
        <div
          className='order-invoice-box invoice-template pr-3'
          ref={templateRef}
        >
          {/* Watermark container */}
          <div className='invoice-watermark'>
            <img
              src={dokanData?.dokan?.logo}
              alt='Watermark Logo'
              className='watermark-logo'
            />
          </div>
          <div className='invoice-header border-bottom'>
            <div className='invoice-logo-section'>
              <img src={dokanData?.dokan?.logo} alt='Company Logo' />
            </div>
            <div className='invoice-info-section'>
              <p className='invoice-text-center '>
                <span style={{ fontSize: '30px' }}>
                  {' '}
                  <strong>{dokanData?.dokan?.title}</strong>
                </span>
                <br />
                {dokanData?.dokan?.setting?.street_address}, <br />
                {dokanData?.dokan?.setting?.post_office},
                {/* {dokanData?.dokan?.setting?.upazilla},{' '} */}
                {dokanData?.dokan?.setting?.state},
                <br />
                {dokanData?.dokan?.setting?.phone},&nbsp;
                {dokanData?.dokan?.setting?.phone2}
                <br />
                {dokanData?.dokan?.setting?.email}
              </p>
            </div>
            <div className='invoice-details-section'>
              {/* generated barcode will be shown here */}
              <div className='invoice-barcode'>
                <svg ref={barcodeRef}></svg>
              </div>

              <div className='invoice-row'>
                <div className='invoice-label'>Invoice &nbsp;</div>
                <div className='invoice-value'>
                  {' '}
                  {invoiceData.invoice_number || invoiceData.uid}
                </div>
              </div>

              <div className='invoice-row'>
                <div className='invoice-label'>Date &nbsp;</div>
                <div className='invoice-value'>
                  {invoiceData?.purchase_date?.split(' ')[0]}
                </div>
              </div>
            </div>
          </div>

          <div className='invoice-row mt-4'>
            <div className='invoice-customer-info'>
              <strong>Customer:</strong> {invoiceData?.customer_info?.name}
              <br />
              <strong>Phone :</strong> {invoiceData?.customer_info?.phone}
            </div>
            <div className='invoice-customer-address'>
              <strong>Address:</strong>{' '}
              {invoiceData?.customer?.district &&
                invoiceData?.customer?.state && (
                  <>
                    {invoiceData?.customer?.street_address},{' '}
                    {invoiceData?.customer?.post_office},{' '}
                    {/* {invoiceData?.customer?.upazilla},{' '} */}
                    {invoiceData?.customer?.district},{' '}
                    {invoiceData?.customer?.state}
                  </>
                )}
            </div>
          </div>

          <div className='invoice-row my-2'>
            <div className='invoice-title invoice-border'>
              <strong>ORDER INVOICE</strong>
            </div>
          </div>

          <div className='row mt-4'>
            <div className='col-md-12'>
              <table className='table table-bordered order-invoice-details'>
                <thead>
                  <tr>
                    <th>SL</th>
                    <th colSpan={2}>Product Name (Code)</th>

                    <th>Warrenty</th>
                    <th>Quantity</th>
                    <th>Unit Price</th>
                    <th style={{ textAlign: 'right' }}>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {invoiceData?.items?.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td colSpan={2}>
                          {item?.product?.name}
                          <br />
                          {item?.product_code ? (
                            <>({item?.product_code?.code})</>
                          ) : (
                            <>
                              {item?.product?.product_code ? (
                                <>({item?.product?.product_code})</>
                              ) : null}{' '}
                            </>
                          )}
                        </td>

                        <td style={{ width: '100px' }}>
                          {item?.warranty_period ? (
                            <>
                              {item?.warranty_period}-{item.warranty_type}
                            </>
                          ) : (
                            ' No'
                          )}{' '}
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          {item?.quantity}
                        </td>
                        <td style={{ width: '120px' }}>৳ {item?.unit_price}</td>

                        <td style={{ textAlign: 'right', width: '100px' }}>
                          ৳ {item?.unit_price * item?.quantity}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>

              <table className='table table-bordered order-invoice-details'>
                <tr className='order-invoice-total-row subtotal-split'>
                  <td colSpan='5'>Subtotal</td>
                  <td> ৳{invoiceData?.busket_total}</td>
                </tr>
                <tr className='order-invoice-total-row'>
                  <td colSpan='5'>Discount</td>
                  <td>৳{invoiceData?.discount}</td>
                </tr>
                <tr className='order-invoice-total-row'>
                  <td colSpan='5'>Total</td>
                  <td>৳{invoiceData?.grand_total}</td>
                </tr>

                <tr className='order-invoice-total-row'>
                  <td colSpan='5'>Paid Amount</td>
                  <td>৳{invoiceData?.amount_paid}</td>
                </tr>
                <tr className='order-invoice-total-row'>
                  <td colSpan='5'>Due Amount</td>
                  <td>৳{invoiceData?.amount_due}</td>
                </tr>
              </table>
            </div>
          </div>

          {/* <div className='row mt-4'>
              <div className='col-md-12'>
                <strong>Amount in words:</strong> {amountInWords} TAKA ONLY
              </div>
            </div> */}

          <div className='invoice-section-container'>
            <div className='order-invoice-terms-section'>
              <p>
                <strong>Terms & Conditions:</strong>
              </p>
              <ul>
                <li>VAT & Taxes are not included in the above price.</li>
                <li>
                  Delivery of Registration Document is subject to clearance of
                  all dues (if any).
                </li>
                <li>
                  SERVICE & WARRANTY SUPPORT: {dokanData?.dokan?.setting?.phone}
                </li>
              </ul>
            </div>
            <div className='invoice-table-container'>
              <div className='invoice-table'>
                <table
                  className='invoice-table-bordered'
                  style={{ tableLayout: 'fixed', width: '100%' }}
                >
                  <thead>
                    <tr>
                      <th colSpan='2' style={{ width: '100%' }}>
                        Previous Balance
                      </th>
                    </tr>
                    <tr>
                      <th style={{ width: '50%' }}>Previous Due</th>
                      <th style={{ width: '50%' }}>
                        {' '}
                        ৳ {invoiceData?.customer_info?.previous_due}
                      </th>
                    </tr>
                    <tr>
                      <th style={{ width: '50%' }}>Invoice Due</th>
                      <th style={{ width: '50%' }}>
                        ৳ {invoiceData?.amount_due}
                      </th>
                    </tr>
                    <tr>
                      <th style={{ width: '50%' }}>Total Due</th>
                      <th style={{ width: '50%' }}>
                        ৳{' '}
                        {invoiceData?.amount_due +
                          invoiceData?.customer_info?.previous_due}
                      </th>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>
          </div>

          <div className='invoice-signature'>
            <div className='invoice-signature-customer'>
              <p>
                ______________________
                <br />
                Customer Signature
              </p>
            </div>
            <div className='invoice-signature-authorized'>
              <p>
                ______________________
                <br />
                Authorized Signature
              </p>
            </div>
          </div>

          {/* <div className='row mt-4'>
              <div className='col-md-12 text-center'>
                <p>
                  <strong>
                    {dokanData?.dokan?.title}, © {moment().year()}
                  </strong>
                </p>
              </div>
            </div> */}
          <div className='row mb-0 print-footer'>
            <div className='col-md-12 text-start' style={{ fontSize: '18px' }}>
              <p>
                Developed by <strong>On Virtual World</strong> | contact :
                01309478609
              </p>
            </div>
          </div>
        </div>
      )}

      {/* Product Show Modal */}
      <div className='hide-during-print'>
        <PrimaryModal
          show={show_product_modal}
          onHide={() => setShowProductModal(false)}
          size='xl'
          title='Product details list'
        >
          {/* Products data */}
          <DataTable
            columns={productColumns}
            data={productData}
            loading={loading}
            totalRows={totalRows}
            handlePerRowsChange={handlePerRowsChange}
            handlePageChange={handlePageChange}
          />
        </PrimaryModal>
      </div>
    </div>
  );
};

import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './style.scss';
import { Menu, Minimize, PlusSquare } from 'react-feather';
import { Image } from '../image/Index';
import Dropdown from './Dropdown';
import { Images } from '../../utils/Images';
import LanguageSelector from '../../components/languageSelector/Index';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Requests } from '../../utils/Http/Index';
import {
  PrimaryButton,
  DangerButton,
  SuccessButton,
  GreenButton,
  RedButton,
} from '../button/Index';
import { Text } from '../text/Text';
import { useSelector } from 'react-redux'; // Import useSelector from Redux

const Index = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [data, setData] = useState(null);
  const logo = useSelector((state) => state.logo.logo); // Access the logo from the Redux store

  const fetchData = useCallback(async () => {
    if (history.location.pathname !== '/shop') {
      try {
        const response = await Requests.Settings.DokanSettingInformation();
        if (response.status === 200) setData(response.data);
      } catch (error) {
        if (error) console.log(error);
      }
    }
  }, [history]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const dokantitle = data ? data?.dokan?.title : '';

  const LogoSrc = logo || (data ? data?.dokan?.logo : Images.DefaultLogo); // Use Redux logo first
  return (
    <div className='navbar-container'>
      <div className='d-flex align-items-center justify-content-center'>
        <div>
          <Image src={LogoSrc} alt='Company logo' x={50} y={50} />
        </div>
        <div className='d-flex text-white mt-3 px-4 align-items-center justify-content-center'>
          <Text>{dokantitle && dokantitle}</Text>
        </div>
        <div className='d-flex align-items-center ml-auto'>
          <Link to='/dashboard/pos/point-of-sell'>
            <GreenButton type='button ' className='mr-2 pos-button'>
              <Text className='mb-0 text-white fs-12 d-flex justify-content-center align-items-center'>
                {' '}
                <Minimize size={12} />
                <span className='ml-1'>POS</span>
              </Text>
            </GreenButton>
          </Link>

          <Link to='/dashboard/accounting/add-expense'>
            <RedButton type='button' className='mr-2 add-expenses-button'>
              <Text className='mb-0 fs-12 d-flex justify-content-center align-items-center'>
                <PlusSquare size={12} />{' '}
                <span className='ml-1'>ADD EXPENSES</span>
              </Text>
            </RedButton>
          </Link>

          {/* <div>
            <LanguageSelector />
          </div> */}

          <div>
            <Dropdown />
          </div>

          {props.menu && (
            <div className='d-lg-none pl-2'>
              <button
                type='button'
                className='btn shadow-none rounded-circle'
                onClick={props.drawer}
              >
                <Menu size={24} />
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Index;

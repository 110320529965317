import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { NoContent } from '../../../components/204/NoContent';
import { NetworkError } from '../../../components/501/NetworkError';
import { Busket } from '../../../components/busket/Index';
import { PrimaryButton } from '../../../components/button/Index';
import { Container } from '../../../components/container/Index';
import { Layout, Main } from '../../../components/layout/Index';
import { Loader } from '../../../components/loading/Index';
import { Product } from '../../../components/product/Index';
import { useQuery } from '../../../components/query/Index';
import { Requests } from '../../../utils/Http/Index';
import {
  addToDatabaseCart,
  getDatabaseCart,
  removeFromDatabaseCart,
} from '../../../utils/utilities';
import { Toastify } from '../../../components/toastify/Toastify';
import { PrimaryModal } from '../../../components/modal/PrimaryModal';
import { toWords } from 'number-to-words';
import moment from 'moment';
import JsBarcode from 'jsbarcode';

const Pos = () => {
  const { t } = useTranslation();
  const onClearRef = useRef();
  const history = useHistory();
  const queryParams = useQuery();
  const [productData, setProductData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [busket, setBusket] = useState([]);
  const [orderUID, setOrderUID] = useState('');
  const [invoiceData, setInvoiceData] = useState([]);
  const [invoiceModal, setInvoiceModal] = useState(false);
  const [invoiceNo, setInvoiceNo] = useState('');
  const [product, setProduct] = useState([]);
  const templateRef = useRef();
  const templateRefHidden = useRef();
  const barcodeRef = useRef(null);
  const barcodeRef2 = useRef(null);

  const savedCart = getDatabaseCart();
  const qnt = Object.keys(savedCart).length;
  const [changedId, setChangedId] = useState(null);
  const [quantity, setQuantity] = useState(qnt ?? 0);
  const [busketproduct, setBusketProd] = useState([]);
  const [busketShake, setBusketShake] = useState(false);
  const [closeFilter, setCloseFilter] = useState(false);

  const [filterNameBarcode, setFilterNameBarcode] = useState('');
  const amountInWords = toWords(invoiceData?.grand_total || 0).toUpperCase();

  // useEffect(() => {
  //   const itemCount = invoiceData?.items?.length;

  //   const subtotalSplit = document.querySelector('.subtotal-split');
  //   const invoiceSectionContainer = document.querySelector(
  //     '.invoice-section-container'
  //   );

  //   if (itemCount <= 2) {
  //     // No page break if there are 2 or fewer items to avoid a blank page
  //     invoiceSectionContainer?.classList.remove('break-page');
  //     subtotalSplit?.classList.remove('break-page');
  //   } else if (itemCount === 3 || itemCount === 4 || itemCount === 5) {
  //     // Add page break for moderate number of items
  //     invoiceSectionContainer?.classList.add('break-page'); // Page break before subtotal
  //     subtotalSplit?.classList.remove('break-page'); // No page break inside subtotal section
  //   } else if (itemCount >= 6) {
  //     // Ensure a page break before the subtotal section for larger number of items
  //     subtotalSplit?.classList.add('break-page');
  //     invoiceSectionContainer?.classList.remove('break-page'); // No break before container
  //   }
  // }, [invoiceData?.items]);

  const generateInvoiceNo = async () => {
    try {
      const response = await Requests.POS.GenerateInvoice();
      setInvoiceNo(response?.data?.data?.invoice_number);
      // setTotalRows(response.data.tota);

      setLoading(false);
      setError(false);
    } catch (error) {
      setLoading(false);
      setError(true);
    }
  };

  const handleGeneratePDF = () => {
    // Wait for the barcode to render
    setTimeout(() => {
      window.print(); // Opens the browser's print dialog after the delay
    }, 500); // 500ms delay to ensure barcode renders
  };

  // for shaking busket of pos page
  const handleBusketShake = (product) => {
    handleChangeInputBusket(product.uid);
    setBusketShake(true);

    fetchProduct();

    setTimeout(() => {
      setBusketShake(false);
    }, 300);
  };

  // for fetching product list
  const fetchData = useCallback(async (page) => {
    setLoading(true);
    try {
      const response = await Requests.Inventory.Product.DokanPosProductList();
      setProductData(response.data.data);
      setLoading(false);
      setError(false);
    } catch (error) {
      setLoading(false);
      setError(true);
    }
  }, []);

  const refreshProductList = useCallback(async () => {
    fetchData(1);
  }, []);

  useEffect(() => {
    if (invoiceNo && barcodeRef.current) {
      JsBarcode(barcodeRef.current, invoiceNo, {
        format: 'CODE128',
        lineColor: '#000',
        width: 2,
        height: 40,
        displayValue: false,
      });
      JsBarcode(barcodeRef2.current, invoiceNo, {
        format: 'CODE128',
        lineColor: '#000',
        width: 2,
        height: 40,
        displayValue: false,
      });
    }
  }, [invoiceNo]);

  // ------------------------ Filter item ---------------
  const handleFilterByUrl = (field, value) => {
    let item = { [field]: value };
    let params = {
      ...queryParams,
      ...item,
    };

    if (params) {
      const queryString = Object.keys(params)
        .map((key) => `${key}=${params[key]}`)
        .join('&');
      history.replace(`point-of-sell?${queryString}`);
      setCloseFilter(true);
    }
  };
  // fetch Invoice data
  const fetchInvoiceData = useCallback(async (orderUID) => {
    try {
      setInvoiceModal(false);

      const response = await Requests.Settings.InvoiceInfo(orderUID);
      if (response.status === 200) setInvoiceData(response.data.data);
      setInvoiceModal(true);
      await generateInvoiceNo(); // Generate the invoice number

      // handleGeneratePDF();
      setLoading(false);
    } catch (error) {
      if (error) {
        setLoading(false);
        setInvoiceModal(false);
      }
    }
  }, []);

  useEffect(() => {
    if (invoiceData) {
      // The API call that relies on orderUID
    }
  }, [invoiceData]);

  useEffect(() => {
    if (orderUID) {
      fetchInvoiceData(orderUID); // The API call that relies on orderUID
    }
  }, [orderUID, fetchInvoiceData]);

  const onSearchProductSubmit = async (e) => {
    e.preventDefault();

    if (!filterNameBarcode) return;

    try {
      const response = await Requests.Inventory.Product.DokanPosProductSearch(
        filterNameBarcode
      );
      if (response.data && response.data.data && response.status === 200) {
        setProductData(response.data.data);
        setLoading(false);
        setError(false);
      }
    } catch (error) {
      // if (error && error.response && error.response.status === 404) {
      //   // console.log("No Brand Found");
      // }

      setLoading(false);
      setError(true);
    }
  };

  // discount calculation
  const discountCalculate = (corePrice, discountType, discountAmount) => {
    let newPrice;

    if (discountType && discountType === 'taka') {
      newPrice = parseInt(corePrice) - parseInt(discountAmount);
    } else {
      const discount = (corePrice * discountAmount) / 100;
      newPrice = parseInt(corePrice - discount);
    }

    return newPrice;
  };

  // handle Busket
  const handleBusket = (product, price) => {
    const main_product = { ...product };

    const toBeAddedKey = main_product.uid;

    const sameProduct =
      busket.length > 0 &&
      busket.find((productItem) => productItem.uid === toBeAddedKey);

    let count = 1;
    let newCart;

    if (sameProduct) {
      Toastify.Error('Product Already in Busket!');

      newCart = [...busket];
    } else {
      main_product.product_quantity = main_product.quantity;

      //   main_product.quantity = 1;
      newCart = [...busket, main_product];
    }

    const selling_price = discountCalculate(
      main_product.selling_price,
      main_product.discount_type,
      main_product.discount_amount
    );

    addToDatabaseCart(
      JSON.stringify({
        uid: main_product.uid,
        name: main_product.name,
        warranty_period: main_product.warranty_period,
        discount_type: main_product.discount_type,
        price: isNaN(price) !== true ? price : selling_price,
        featured_image: main_product.featured_image,
        product_quantity: sameProduct
          ? sameProduct.product_quantity
          : main_product.quantity,
        is_code_separate: main_product.is_code_separate == 1 ? 1 : 0,
        code_uid: main_product.code_uid ? main_product.code_uid : '',
      }),
      count
    );

    setBusket(newCart);
  };

  // for busket
  const handleChangeInputBusket = (id, event = null, types = null) => {
    const newInputFields = product.map((item) => {
      setChangedId(id);
      if (id === item.value) {
        item['product_uid'] = item.value;
        if (types === 'price') {
          item['price'] = event.target.value;
        }
        if (types === 'quantity') {
          item['quantity'] = event.target.value;
        }
        if (types === 'warrenty') {
          item['warrenty'] = event.target.value ?? 0;
        }
      }

      return item;
    });
    setBusketProd(newInputFields);
  };

  // fetch product
  const fetchProduct = useCallback(async () => {
    const product = [];
    Object.keys(getDatabaseCart()).map((item) => {
      const item2 = JSON.parse(item);
      product.push({
        value: item2.uid,
        label: item2.name,
        price: item2.price,
        quantity: 1,
        warrenty: parseInt(item2.warranty_period) ?? 0,
        product_quantity: item2.product_quantity,
        is_code_separate: item2.is_code_separate == 0 ? 0 : 1,
        code_uid: item2.code_uid ? item2.code_uid : '',
      });
      return product;
    });
    setBusketProd(product);
    setProduct(product);
  }, []);

  // for deleting quantity
  const handleDeleteQuantity = (data) => {
    removeFromDatabaseCart(data);
    setQuantity(quantity - 1);
    fetchProduct();
  };

  useEffect(() => {
    if (queryParams) {
      let params = { ...queryParams };
      const queryString = Object.keys(params)
        .map((key) => `${key}=${params[key]}`)
        .join('&');
      fetchData(queryString);
      setCloseFilter(true);
    }
    fetchProduct();
  }, [
    queryParams.product,
    queryParams.category,
    queryParams.brand,
    fetchData,
    fetchProduct,
  ]);

  return (
    <div>
      <div className='hide-during-print'>
        <Layout
          page='pos / point of sell'
          message={t('Product From Your Store.')}
          shorttext='All Products from your store is here'
          container='container-fluid'
        />
      </div>

      <Main className='hide-during-print'>
        {loading && !productData.length ? <Loader /> : null}

        {!loading && error && !productData.length ? (
          <NetworkError message='Network Error.' />
        ) : (
          <>
            <Container.Fluid>
              <form onSubmit={onSearchProductSubmit}>
                <Container.Row>
                  <Container.Column className='col-xl-5 col-lg-4 md-sm-2 mb-2'>
                    <input
                      type='text'
                      className='form-control shadow-none'
                      placeholder={t('Enter product name/barcode/imei')}
                      onChange={(e) => {
                        setFilterNameBarcode(e.target.value);
                      }}
                    />
                  </Container.Column>

                  <Container.Column className='col-xl-3 col-lg-3 md-sm-3 text-start mb-2'>
                    <PrimaryButton
                      type='submit'
                      className='px-4'
                      // disabled={loading}
                    >
                      <span>Search</span>
                    </PrimaryButton>
                  </Container.Column>
                </Container.Row>
              </form>

              {!loading && !productData.length ? (
                <NoContent message='No Content.' />
              ) : (
                <>
                  <Container.Row>
                    <Container.Column className='pb-5 pt-4'>
                      {productData.map((item, index) => {
                        return (
                          <Product
                            key={index}
                            product={item}
                            busketShake={handleBusketShake}
                            // busketShake={()=>{}}
                            // handleProduct={()=>{}}
                            handleProduct={handleBusket}
                          />
                        );
                      })}
                    </Container.Column>
                  </Container.Row>
                </>
              )}
            </Container.Fluid>
            <Busket
              // new two
              busketShake={handleBusketShake}
              handleProduct={handleBusket}
              busket={busketShake}
              fetchProduct={fetchProduct}
              product={product}
              handleDeleteQuantity={handleDeleteQuantity}
              qnt={qnt}
              handleChangeInputBusket={handleChangeInputBusket}
              busketproduct={busketproduct}
              setOrderUID={setOrderUID}
              productid={changedId}
              refreshProductList={() => {
                refreshProductList();
              }}
            />
          </>
        )}
      </Main>
      <div className='order-invoice-box invoice-template' ref={templateRef}>
        {/* Watermark container */}
        <div className='invoice-watermark'>
          <img
            src={invoiceData?.dokan?.logo}
            alt='Watermark Logo'
            className='watermark-logo'
          />
        </div>
        <div className='invoice-header border-bottom'>
          <div className='invoice-logo-section'>
            <img src={invoiceData?.dokan?.logo} alt='Company Logo' />
          </div>
          <div className='invoice-info-section'>
            <p className='invoice-text-center '>
              <span style={{ fontSize: '30px' }}>
                <strong>{invoiceData?.dokan?.title}</strong>
              </span>
              <br />
              {invoiceData?.dokan?.setting?.street_address}, <br />
              {invoiceData?.dokan?.setting?.post_office},{' '}
              {/* {invoiceData?.dokan?.setting?.upazilla},{' '} */}
              {invoiceData?.dokan?.setting?.state},
              <br />
              {invoiceData?.dokan?.setting?.phone},&nbsp;
              {invoiceData?.dokan?.setting?.phone2}
              <br />
              {invoiceData?.dokan?.setting?.email}
            </p>
          </div>
          <div className='invoice-details-section'>
            {/* generated barcode will be shown here */}
            <div className='invoice-barcode'>
              <svg ref={barcodeRef}></svg>
            </div>

            <div className='invoice-row'>
              <div className='invoice-label'>Invoice &nbsp;</div>
              {invoiceData.invoice_number || invoiceData.uid}
            </div>

            <div className='invoice-row'>
              <div className='invoice-label'>Date &nbsp;</div>
              <div className='invoice-value'>
                {invoiceData?.purchase_date?.split(' ')[0]}
              </div>
            </div>
          </div>
        </div>

        <div className='invoice-row mt-4'>
          <div className='invoice-customer-info'>
            <strong>Customer:</strong> {invoiceData?.customer_info?.name}
            <br />
            <strong> Phone:</strong> {invoiceData?.customer_info?.phone}
          </div>
          <div className='invoice-customer-address'>
            <strong>Address:</strong>{' '}
            {invoiceData?.customer?.district &&
              invoiceData?.customer?.state && (
                <>
                  {invoiceData?.customer?.street_address},{' '}
                  {invoiceData?.customer?.post_office},{' '}
                  {/* {invoiceData?.customer?.upazilla},{' '} */}
                  {invoiceData?.customer?.district},{' '}
                  {invoiceData?.customer?.state}
                </>
              )}
          </div>
        </div>

        <div className='invoice-row my-2'>
          <div className='invoice-title invoice-border'>
            <strong>ORDER INVOICE</strong>
          </div>
        </div>

        <div className='row mt-4'>
          <div className='col-md-12'>
            <table className='table table-bordered order-invoice-details'>
              <thead>
                <tr>
                  <th>SL</th>
                  <th colSpan={2}>Product Name (Code)</th>

                  <th>Warrenty</th>
                  <th>Quantity</th>
                  <th>Unit Price</th>
                  <th style={{ textAlign: 'right' }}>Total</th>
                </tr>
              </thead>
              <tbody>
                {invoiceData?.items?.map((item, index) => {
                  return (
                    <tr>
                      <td>{index + 1}</td>
                      <td colSpan={2}>
                        {item?.product?.name}
                        <br />
                        {item?.product_code ? (
                          <>({item?.product_code?.code})</>
                        ) : (
                          <>
                            {item?.product?.product_code ? (
                              <>({item?.product?.product_code})</>
                            ) : null}{' '}
                          </>
                        )}
                      </td>

                      <td style={{ width: '100px' }}>
                        {item?.warranty_period ? (
                          <>
                            {item?.warranty_period}-{item.warranty_type}
                          </>
                        ) : (
                          ' No'
                        )}{' '}
                      </td>
                      <td style={{ textAlign: 'center' }}>{item?.quantity}</td>
                      <td style={{ width: '120px' }}>৳ {item?.unit_price}</td>

                      <td style={{ textAlign: 'right', width: '100px' }}>
                        ৳ {item?.unit_price * item?.quantity}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>

            <table className='table table-bordered order-invoice-details'>
              <tr className='order-invoice-total-row subtotal-split'>
                <td colSpan='5'>Subtotal</td>
                <td> ৳{invoiceData?.busket_total}</td>
              </tr>
              <tr className='order-invoice-total-row'>
                <td colSpan='5'>Discount</td>
                <td>৳{invoiceData?.discount}</td>
              </tr>
              <tr className='order-invoice-total-row'>
                <td colSpan='5'>Total</td>
                <td>৳{invoiceData?.grand_total}</td>
              </tr>

              <tr className='order-invoice-total-row'>
                <td colSpan='5'>Paid Amount</td>
                <td>৳{invoiceData?.amount_paid}</td>
              </tr>
              <tr className='order-invoice-total-row'>
                <td colSpan='5'>Due Amount</td>
                <td>৳{invoiceData?.amount_due}</td>
              </tr>
            </table>
          </div>
        </div>

        {/* <div className='row mt-4'>
          <div className='col-md-12'>
            <strong>Amount in words:</strong> {amountInWords} TAKA ONLY
          </div>
        </div> */}

        <div className='invoice-section-container'>
          <div className='order-invoice-terms-section'>
            <p>
              <strong>Terms & Conditions:</strong>
            </p>
            <ul>
              <li>VAT & Taxes are not included in the above price.</li>
              <li>
                Delivery of Registration Document is subject to clearance of all
                dues (if any).
              </li>
              <li>
                SERVICE & WARRANTY SUPPORT: {invoiceData?.dokan?.setting?.phone}
              </li>
            </ul>
          </div>
          <div className='invoice-table-container'>
            <div className='invoice-table'>
              <table
                className='invoice-table-bordered'
                style={{ tableLayout: 'fixed', width: '100%' }}
              >
                <thead>
                  <tr>
                    <th colSpan='2' style={{ width: '100%' }}>
                      Previous Balance
                    </th>
                  </tr>
                  <tr>
                    <th style={{ width: '50%' }}>Previous Due</th>
                    <th style={{ width: '50%' }}>
                      ৳ {invoiceData?.customer_info?.previous_due}
                    </th>
                  </tr>
                  <tr>
                    <th style={{ width: '50%' }}>Invoice Due</th>
                    <th style={{ width: '50%' }}>
                      ৳ {invoiceData?.amount_due}
                    </th>
                  </tr>
                  <tr>
                    <th style={{ width: '50%' }}>Total Due</th>
                    <th style={{ width: '50%' }}>
                      ৳{' '}
                      {invoiceData?.amount_due +
                        invoiceData?.customer_info?.previous_due}
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
        </div>

        <div className='invoice-signature'>
          <div className='invoice-signature-customer'>
            <p>
              ______________________
              <br />
              Customer Signature
            </p>
          </div>
          <div className='invoice-signature-authorized'>
            <p>
              ______________________
              <br />
              Authorized Signature
            </p>
          </div>
        </div>

        {/* <div className='row mb-4'>
          <div className='col-md-12 text-center'>
            <p>
              <strong>
                {invoiceData?.dokan?.title}, © {moment().year()}
              </strong>
            </p>
          </div>
        </div> */}
        <div className='row  print-footer'>
          <div className='col-md-12 text-start' style={{ fontSize: '18px' }}>
            <p>
              Developed by <strong>On Virtual World</strong> | Contact :
              01309478609
            </p>
          </div>
        </div>
      </div>
      <div className='hide-during-print'>
        <PrimaryModal
          show={invoiceModal}
          onHide={() => setInvoiceModal(false)}
          title='Download Invoice'
          size='lg'
          showDownload={true}
          handleGeneratePDF={handleGeneratePDF}
        >
          <div
            className='order-invoice-box2 invoice-template2'
            ref={templateRefHidden}
          >
            <div className='invoice-watermark'>
              <img
                src={invoiceData?.dokan?.logo}
                alt='Watermark Logo'
                className='watermark-logo'
              />
            </div>
            <div className='invoice-header border-bottom'>
              <div className='invoice-logo-section'>
                <img src={invoiceData?.dokan?.logo} alt='Company Logo' />
              </div>
              <div className='invoice-info-section'>
                <p className='invoice-text-center '>
                  <span style={{ fontSize: '30px' }}>
                    {' '}
                    <strong>{invoiceData?.dokan?.title}</strong>
                  </span>
                  <br />
                  {invoiceData?.dokan?.setting?.street_address}, <br />
                  {invoiceData?.dokan?.setting?.post_office},
                  {/* {invoiceData?.dokan?.setting?.upazilla},{' '} */}
                  {invoiceData?.dokan?.setting?.state},
                  <br />
                  {invoiceData?.dokan?.setting?.phone},&nbsp;
                  {invoiceData?.dokan?.setting?.phone2}
                  <br />
                  {invoiceData?.dokan?.setting?.email}
                </p>
              </div>
              <div className='invoice-details-section'>
                {/* generated barcode will be shown here */}
                <div className='invoice-barcode'>
                  <svg ref={barcodeRef2}></svg>
                </div>

                <div className='invoice-row'>
                  <div className='invoice-label'>Invoice &nbsp;</div>
                  <div className='invoice-value'>
                    {' '}
                    {invoiceData.invoice_number || invoiceData.uid}
                  </div>
                </div>

                <div className='invoice-row'>
                  <div className='invoice-label'>Date &nbsp;</div>
                  <div className='invoice-value'>
                    {invoiceData?.purchase_date?.split(' ')[0]}
                  </div>
                </div>
              </div>
            </div>

            <div className='invoice-row mt-4'>
              <div className='invoice-customer-info'>
                <strong>Customer:</strong> {invoiceData?.customer_info?.name}
                <br />
                <strong>Phone:</strong> {invoiceData?.customer_info?.phone}
              </div>
              <div className='invoice-customer-address'>
                <strong>Address:</strong>{' '}
                {invoiceData?.customer?.district &&
                  invoiceData?.customer?.state && (
                    <>
                      {invoiceData?.customer?.street_address},{' '}
                      {invoiceData?.customer?.post_office},{' '}
                      {invoiceData?.customer?.upazilla},{' '}
                      {invoiceData?.customer?.district},{' '}
                      {invoiceData?.customer?.state}
                    </>
                  )}
              </div>
            </div>

            <div className='invoice-row my-2'>
              <div className='invoice-title invoice-border'>
                <strong>ORDER INVOICE</strong>
              </div>
            </div>

            <div className='row mt-4'>
              <div className='col-md-12'>
                <table className='table table-bordered order-invoice-details'>
                  <thead>
                    <tr>
                      <th>SL</th>
                      <th colSpan={2}>Product Name (Code)</th>

                      <th>Warrenty</th>
                      <th>Quantity</th>
                      <th>Unit Price</th>
                      <th style={{ textAlign: 'right' }}>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {invoiceData?.items?.map((item, index) => {
                      return (
                        <tr>
                          <td>{index + 1}</td>
                          <td colSpan={2}>
                            {item?.product?.name}
                            <br />
                            {item?.product_code ? (
                              <>({item?.product_code?.code})</>
                            ) : (
                              <>
                                {item?.product?.product_code ? (
                                  <>({item?.product?.product_code})</>
                                ) : null}{' '}
                              </>
                            )}
                          </td>

                          <td style={{ width: '100px' }}>
                            {item?.warranty_period ? (
                              <>
                                {item?.warranty_period}-{item.warranty_type}
                              </>
                            ) : (
                              ' No'
                            )}{' '}
                          </td>
                          <td style={{ textAlign: 'center' }}>
                            {item?.quantity}
                          </td>
                          <td style={{ width: '120px' }}>
                            ৳ {item?.unit_price}
                          </td>

                          <td style={{ textAlign: 'right', width: '100px' }}>
                            ৳ {item?.unit_price * item?.quantity}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <table className='table table-bordered order-invoice-details'>
                  <tr className='order-invoice-total-row subtotal-split'>
                    <td colSpan='5'>Subtotal</td>
                    <td> ৳{invoiceData?.busket_total}</td>
                  </tr>
                  <tr className='order-invoice-total-row'>
                    <td colSpan='5'>Discount</td>
                    <td>৳{invoiceData?.discount}</td>
                  </tr>
                  <tr className='order-invoice-total-row'>
                    <td colSpan='5'>Total</td>
                    <td>৳{invoiceData?.grand_total}</td>
                  </tr>

                  <tr className='order-invoice-total-row'>
                    <td colSpan='5'>Paid Amount</td>
                    <td>৳{invoiceData?.amount_paid}</td>
                  </tr>
                  <tr className='order-invoice-total-row'>
                    <td colSpan='5'>Due Amount</td>
                    <td>৳{invoiceData?.amount_due}</td>
                  </tr>
                </table>
              </div>
            </div>

            {/* <div className='row mt-4'>
            <div className='col-md-12'>
              <strong>Amount in words:</strong> {amountInWords} TAKA ONLY
            </div>
          </div> */}

            <div className='invoice-section-container'>
              <div className='order-invoice-terms-section'>
                <p>
                  <strong>Terms & Conditions:</strong>
                </p>
                <ul>
                  <li>VAT & Taxes are not included in the above price.</li>
                  <li>
                    Delivery of Registration Document is subject to clearance of
                    all dues (if any).
                  </li>
                  <li>
                    SERVICE & WARRANTY SUPPORT:{' '}
                    {invoiceData?.dokan?.setting?.phone}
                  </li>
                </ul>
              </div>
              <div className='invoice-table-container'>
                <div className='invoice-table'>
                  <table
                    className='invoice-table-bordered'
                    style={{ tableLayout: 'fixed', width: '100%' }}
                  >
                    <thead>
                      <tr>
                        <th colSpan='2' style={{ width: '100%' }}>
                          Previous Balance
                        </th>
                      </tr>
                      <tr>
                        <th style={{ width: '50%' }}>Previous Due</th>
                        <th style={{ width: '50%' }}>
                          {' '}
                          ৳ {invoiceData?.customer_info?.previous_due}
                        </th>
                      </tr>
                      <tr>
                        <th style={{ width: '50%' }}>Invoice Due</th>
                        <th style={{ width: '50%' }}>
                          ৳ {invoiceData?.amount_due}
                        </th>
                      </tr>
                      <tr>
                        <th style={{ width: '50%' }}>Total Due</th>
                        <th style={{ width: '50%' }}>
                          ৳{' '}
                          {invoiceData?.amount_due +
                            invoiceData?.customer_info?.previous_due}
                        </th>
                      </tr>
                    </thead>
                  </table>
                </div>
              </div>
            </div>

            <div className='invoice-signature'>
              <div className='invoice-signature-customer'>
                <p>
                  ______________________
                  <br />
                  Customer Signature
                </p>
              </div>
              <div className='invoice-signature-authorized'>
                <p>
                  ______________________
                  <br />
                  Authorized Signature
                </p>
              </div>
            </div>

            <div className='row mb-4'>
              <div className='col-md-12 text-center'>
                <p>
                  <strong>
                    {invoiceData?.dokan?.title}, © {moment().year()}
                  </strong>
                </p>
              </div>
            </div>
            <div className='row mb-0 print-footer'>
              <div
                className='col-md-12 text-start'
                style={{ fontSize: '15px' }}
              >
                <p>
                  Developed by <strong>On Virtual World</strong> | Contact :
                  01309478609
                </p>
              </div>
            </div>
          </div>
        </PrimaryModal>
      </div>
    </div>
  );
};

export default Pos;
